.opacity {
  background-color: #f1eeeb00;
}

.nav-link {
  font-family: "miller-banner", serif;
  font-weight: 500;
  font-style: normal;
  color: #130e0c;
  font-size: x-large;
}

.nav-link:hover {
  text-decoration: underline;
  color: #130e0c;
}
