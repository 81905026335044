.medium {
  max-width: 40%;
}

.small {
  max-width: 15%;
}

.large {
  max-width: 90%;
}
