.Home {
  /* background-image: url('../../imgs/bsco-home-img.png');
  background-size: cover; */
  background-color: #ccbfb0;
  height: 100vh;
  animation: fadeIn 4s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

h1 {
  font-family: "miller-banner", serif;
  font-weight: 500;
  font-style: normal;
  color: #130e0c;
  font-size: xx-large;
}

h5 {
  font-family: "miller-banner", serif;
  font-weight: 300;
  font-style: normal;
  color: #130e0c;
}
