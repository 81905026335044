.Listing {
  background-image: url("../../imgs/bsco-listing-bg.png");
  background-size: cover;
  height: 95vh;
  animation: fadeIn 3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
