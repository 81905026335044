.Services {
  min-height: 100vh;
  animation: fadeIn 4s;
}

.full-screen {
  height: 100vh;
}

.half-hieght {
  height: 50vh;
}

.rotate {
  transform: rotate(-90deg);
}

.designer-bg {
  background-image: url("../../imgs/interiorDesigner2.jpeg");
  background-size: cover;
}

.square {
  height: 50%;
  width: 100%;
  background-color: #eaeaea;
  border-radius: 15px;
  box-shadow: black;
}
