.dark-font {
  color: #130e0c;
}

.gray-font {
  color: #555555;
}

.light-brown-bg {
  background-color: #ccbfb0;
}

.egg-shell-bg {
  background-color: #f1eeeb80;
}

.lime-green {
  background-color: #eaff82;
}

.padded-row {
  height: 50px;
}

.shadowed-boarder-gray {
  border-radius: 5px;
  border: 5px solid #55555550;
}

.shadowed-boarder-black {
  border-radius: 5px;
  border: 5px solid #130e0c50;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "miller-banner", serif;
  font-weight: 800;
  font-style: italic;
}

a {
  color: #130e0c;
}
